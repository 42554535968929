#toolbar {
    position: absolute;
    display: flex;
    flex-direction: column;

    right: 0;
    bottom: 50px;

    /* width: 50px; */
    height: 250px;

    padding: 10px;

    background-color: #aaa4;

    border: 1px solid #8886;
    border-radius: 10px 0 0 10px;
}

#toolbar .item {
    margin: 10px 0;

    text-align: center;
}
